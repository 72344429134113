<script setup lang="ts">
import { JsonViewer } from "vue3-json-viewer";
import "vue3-json-viewer/dist/index.css";
import { link, userHasRole } from "~/lib/utils/contents";
import type { CreateLinkConfig } from "~/utils/types";

const { data: loggedUser } = useLoggedUser(null, true, null, "logged-user-admin");

defineProps<{
  config: CreateLinkConfig;
  data?: any;
}>();
</script>

<template>
  <div class="main-content mb-10 border border-primary-100 p-3" v-if="loggedUser && userHasRole(loggedUser, ['SuperAdmin', 'Amministratore', 'Editore'])">
    <h2 class="flex items-center text-xl font-semibold mb-2">Strumenti per la redazione</h2>
    <div class="flex flex-col">
      <h3 class="font-semibold text-base py-2">
        <NuxtLink :to="link(config, true)" title="Modifica nel pannello di amministrazione" class="hover:text-primary-600 transition-colors">Modifica nel pannello di amministrazione</NuxtLink>
      </h3>
      <Accordion collapsible default-value="">
        <AccordionItem value="json" class="border-none">
          <AccordionTrigger class="font-semibold text-base py-2">Contenuto JSON</AccordionTrigger>
          <AccordionContent>
            <JsonViewer :value="data ? data : config" />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  </div>
</template>
